@font-face {
  font-family: Antipasto;
  src: url(fonts/AntipastoPro-Bold.ttf);
}

* {
  padding: 0;
  margin: 0;
  outline: none;
}

.container {
  display: flex;
  width: 400px;
  padding: 4vh;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 auto;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.logo {
  background: url(images/logo.png) center no-repeat;
  background-size: contain;
  width: 100%;
  min-height: 30vh;
}

.loader-wrapper {
  padding: 4vh 0;
}

.text {
  margin-top: 2vh;
  font-family: Antipasto, sans-serif;
  text-align: center;
  line-height: 20px;
}

.progress-bar {
  border-radius: 20px;
  height: 3vh;
}

.main-bar {
  background-color: #EBEBEB;
  width: 100%;
}

.main-bar .filled {
  background-color: #DFDFDF;
  width: 60%;
}

.main-bar .filled .animated {
  background-image: url(images/bg.png);
  background-size: 120px;
  background-position: 0 0;
  width: 95%;
}

.loader {
  color: #dfdfdf;
  font-size: 5vh;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 5vh auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.flow {
  -webkit-animation: flow 4s infinite linear;
  -moz-animation: flow 4s infinite linear;
  -ms-animation: flow 4s infinite linear;
  -o-animation: flow 4s infinite linear;
  animation: flow 4s infinite linear;
}

@keyframes flow {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -120px 0;
  }

}

@-moz-keyframes flow {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -120px 0;
  }

}

@-webkit-keyframes flow {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -120px 0;
  }

}

@-ms-keyframes flow {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -120px 0;
  }
}
